import styles from './sectionHeading.module.css';

interface Props {
	text: string;
}

export function SectionHeading({ text }: Props) {
	return (
		<div className={styles.sectionHeading} data-aos="fade-up">
			<div>
				<h2>{text}</h2>
			</div>
		</div>
	);
}
