import '@/styles/components/footer.scss';
import { ResponseData } from './piePagina';

const piePaginaData: ResponseData = {
	attributes: {
		copyright: '© 2024 TM Peluquería y Barbería.',
		redes: {
			data: [
				{
					attributes: {
						href: 'https://www.instagram.com/tm_peluqueriaybarberia/',
						nombre: 'Instagram',
						spriteName: 'instagram',
						order: 1
					}
				},
				{
					attributes: {
						href: 'https://g.co/kgs/TdZrkAW',
						nombre: 'Ubicación',
						spriteName: 'location',
						order: 2
					}
				},
				{
					attributes: {
						href: 'https://wa.link/xr9qu3',
						nombre: 'Whatsapp',
						spriteName: 'whatsapp',
						order: 3
					}
				}
			]
		}
	}
};
function Footer() {
	const { attributes } = piePaginaData;
	return (
		<footer className="footer">
			<div className="footer-container section-x">
				<ul>
					{attributes.redes.data.map((red) => (
						<li key={red.attributes.href}>
							<a href={red.attributes.href} aria-label={`Enlace a ${red.attributes.nombre}`} target="_blank">
								<svg>
									<use href={`/svgs/sprite.svg#${red.attributes.spriteName}`}></use>
								</svg>
							</a>
						</li>
					))}
				</ul>
				<p>{attributes.copyright}</p>
			</div>
		</footer>
	);
}

export default Footer;
