import styles from './separator.module.css';
interface Props {
	icon: string;
}

export function Separator({ icon }: Props) {
	return (
		<div className={styles.separator} data-aos="fade-up">
			<svg>
				<use href={`/svgs/sprite.svg#${icon}`}></use>
			</svg>
		</div>
	);
}
