import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--open-sans\"}],\"variableName\":\"openSans\"}");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Cormorant_Garamond\",\"arguments\":[{\"weight\":[\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--cormorant-garamond\"}],\"variableName\":\"cormorantGaramond\"}");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/globals.css");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/AOSComponent/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/firstLoadHandler/FirstLoadHandler.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/header/Header.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/nonReusableComponents/appProviders/AppProviders.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/index.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/components/fixedWhatsapp.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/components/footer.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/sectionHeading/sectionHeading.module.css");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/separator/separator.module.css")