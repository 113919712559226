// AppProviders.tsx
'use client';

import { ReactNode } from 'react';
import FirstLoadContextProvider from '@/contexts/FirstLoadContext';
import { FirstLoadHandler } from '@/components';

export default function AppProviders({ children }: { children: ReactNode }) {
	return (
		<FirstLoadContextProvider>
			<FirstLoadHandler>{children}</FirstLoadHandler>
		</FirstLoadContextProvider>
	);
}
